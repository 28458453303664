import { Component } from '@angular/core';
import { TabService, UniModules } from '../../layout/navbar/tabstrip/tabService';
import { UniQueryDefinition } from '../../../unientities';
import { Router } from '@angular/router';
import { UniQueryDefinitionService } from '@app/services/common/uniQueryDefinitionService';

class UniQueryCategory {
    public name: string;
    public queries: Array<UniQueryDefinition>;
}

@Component({
    selector: 'uni-query-overview',
    templateUrl: './overview.html',
    standalone: false,
    styles: `
        .category-grid {
            display: grid;
            gap: 1.5rem;
            grid-template-columns: 1fr 1fr 1fr;
            max-width: 1800px;
            margin: 2rem auto;
        }

        @media (max-width: 1400px) {
            .category-grid {
                grid-template-columns: 1fr 1fr;
            }
        }

        @media (max-width: 700px) {
            .category-grid {
                grid-template-columns: 1fr;
            }
        }

        a {
            color: var(--text-default);
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }
    `,
})
export class UniQueryOverview {
    public queryCategories: Array<UniQueryCategory>;

    public toolbarActions = [
        {
            label: 'Nytt uttrekk',
            action: this.newQuery.bind(this),
            main: true,
            disabled: false,
        },
    ];

    constructor(
        private tabService: TabService,
        private uniQueryDefinitionService: UniQueryDefinitionService,
        private router: Router,
    ) {
        this.tabService.addTab({
            name: 'Uttrekkoversikt',
            url: '/uniqueries/overview',
            moduleID: UniModules.UniQuery,
            active: true,
        });
    }

    public newQuery() {
        this.router.navigateByUrl('/uniqueries/details/0');
    }

    public ngOnInit() {
        this.uniQueryDefinitionService.GetAll(null).subscribe((uniQueries: Array<UniQueryDefinition>) => {
            this.queryCategories = new Array<UniQueryCategory>();

            for (const uniQuery of uniQueries) {
                const categoryName = uniQuery.Category || uniQuery.MainModelName;

                let queryCategory: UniQueryCategory = this.queryCategories.find(
                    (category) => category.name === categoryName,
                );

                if (typeof queryCategory === 'undefined') {
                    queryCategory = new UniQueryCategory();

                    queryCategory.name = categoryName;
                    queryCategory.queries = new Array<UniQueryDefinition>();

                    this.queryCategories.push(queryCategory);
                }
                queryCategory.queries.push(uniQuery);
            }
        });
    }
}
