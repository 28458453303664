<uni-toolbar [config]="{ title: 'Oversikt over uttrekk' }" [saveactions]="toolbarActions"> </uni-toolbar>
<section class="application">
    <section class="category-grid">
        @for (category of queryCategories; track category) {
            <uni-card>
                <uni-card-header>{{ category.name }}</uni-card-header>

                <div class="flex-column gap-2">
                    @for (query of category.queries; track query) {
                        <a [routerLink]="['../details/' + query.ID]" class="flex-column text-sm">
                            <strong class="report_name">{{ query.Name }}</strong>
                            <span class="color-subdued">{{ query.Description }}</span>
                        </a>
                    }
                </div>
            </uni-card>
        }
    </section>
</section>
<router-outlet></router-outlet>
